
.on_hover:hover {
    cursor: pointer;
}

.vue-universal-modal {
  z-index: 20;
}

.closeModalButton {
  position: absolute;
  z-index: 100;
  right: -30px;
  top: -30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 20px;
  color: rgb(150, 147, 147)
}
.closeModalButtonCarousel {
  position: absolute;
  z-index: 100;
  right: 3%;
  top: 3%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 20px;
  color: rgb(150, 147, 147)
}

.smModal {
  border-radius: 5px;
  width: 400px;
  min-height: 150px;
  padding: 30px;
  // max-height: 90vh;
  // overflow-y: scroll;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}
  

.mdModal {
  border-radius: 5px;
  width: 800px;
  padding: 30px;
  // max-height: 90vh;
  // overflow-y: scroll;
  min-height: 600px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}

.lgModal {
  border-radius: 5px;
  width: 95%;
  padding: 30px;
  // max-height: 90vh;
  // overflow-y: scroll;
  min-height: 600px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}

.fullModal {
  border-radius: 5px;
  width: 95vw;
  height: 95vh;
  padding: 0;
  // max-height: 90vh;
  // overflow-y: scroll;
  // min-height: 600px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}

.scrollContainer {
  max-height: calc(90vh - 200px);
  overflow-y: scroll;
}
  
.vs__dropdown-option--selected {
  background-color: rgb(74, 177, 177);
  color: white!important;
}

.vs__dropdown-option--highlight {
  background-color: rgba(163, 160, 160, 0.3)!important;

}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  transform: rotate(-180deg);
}

.p-button .p-button-label {
  color: #2196F3
}
.p-button:not(.p-button-text):hover .p-button-label {
  color: #fff
}

.innerAccordion .vs--disabled .vs__dropdown-toggle, .innerAccordion .vs--disabled .vs__search {
  background-color: #f0fdff !important;
}

.select-as-text .vs__dropdown-toggle {
  border: none!important;
}

.select-as-text .vs__open-indicator-toggle {
  opacity: 0!important;
}

.select-as-text .vs__actions {
  display: none!important;
}

.ql-container {
  min-height: 300px;
}

.accordion-collapse ol {
  list-style:decimal!important;
}

.accordion-collapse ul {
  list-style:disc!important;
}