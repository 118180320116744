// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


@import "customizing";


@import '~primevue/resources/themes/saga-blue/theme.css';       //theme
@import '~primevue/resources/primevue.min.css';                 //core css
@import '~primeicons/primeicons.css';